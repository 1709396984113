import React from 'react';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';

const H2Dashboard = ({ rawData = [] }) => {

    const processData = (data) => {
        const summary = {};

        data?.forEach(region => {
            region?.drg_groups?.forEach(group => {
                if (!group?.code) return;

                const firstLetter = group.code.charAt(0);

                if (!summary[firstLetter]) {
                    summary[firstLetter] = {
                        codeName: firstLetter,
                        value: 0,
                        details: {}
                    };
                }

                summary[firstLetter].value += group.report_forms_count;

                // Summarize details by ID and code
                const detailKey = `${group.id}-${group.code}`;
                if (!summary[firstLetter].details[detailKey]) {
                    summary[firstLetter].details[detailKey] = {
                        id: group.id,
                        code: group.code,
                        name: group.name,
                        count: 0
                    };
                }

                summary[firstLetter].details[detailKey].count += group.report_forms_count;
            });
        });

        // Convert details from object to array
        Object.values(summary).forEach(group => {
            group.details = Object.values(group.details);
        });

        return Object.values(summary);
    };

    const groupedData = processData(rawData);
    const totalForms = groupedData.reduce((sum, group) => sum + group.value, 0);

    const pieChartData = groupedData.map(group => ({
        ...group,
        percent: ((group.value / totalForms) * 100).toFixed(2),
    }));

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            const totalGroupForms = data.value;

            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', maxHeight: '300px', overflowY: 'auto' }}>
                    <p><strong>Группа:</strong> {data.codeName} (Всего: {data.value}, {data.percent}%)</p>
                    <hr />
                    <strong>Детали:</strong>
                    <div className={`grid ${data.details.length > 10 ? 'grid-cols-2' : 'grid-cols-1' } gap-1`}>
                        {data.details.map((item, index) => (
                            <div key={index}>
                                <p>
                                    <strong>{item.code}:</strong> {item.name} | {item.count} ({((item.count / totalGroupForms) * 100).toFixed(2)}%)
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        return null;
    };

    const COLORS = ["#F2A19D", "#77706F", "#EBCF73", "#5E9694", "#D1605E", "#9CCF85", "#E49343", "#B29A42", "#F29E9A"];

    if (!pieChartData || pieChartData.length === 0) {
        return (
            <div style={{ textAlign: 'center', position: 'relative' }}>
                <ResponsiveContainer width="100%" height={400}>
                    <PieChart>
                        <Pie
                            data={[{ name: '', value: 1 }]}
                            cx="50%"
                            cy="50%"
                            outerRadius={150}
                            fill="#EBCF73"
                            dataKey="value"
                        />
                    </PieChart>
                </ResponsiveContainer>
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#666',
                    }}
                >
                    Нет данных
                </div>
            </div>
        );
    }

    return (
        <div className={'w-full h-full flex justify-center relative'}>
            <ResponsiveContainer width="100%" height={600}>
                <PieChart width={800} height={600}>
                    <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        label={({ value, codeName, x, y }) => {
                            const totalForms = pieChartData.reduce((sum, entry) => sum + entry.value, 0);
                            const percentage = ((value / totalForms) * 100).toFixed(2);

                            return (
                                <text x={x} y={y} textAnchor="middle" fill="#000" fontSize={14}>
                                    <tspan x={x} dy="-1.2em">{codeName}</tspan>
                                    <tspan x={x} dy="1.2em">{percentage}%</tspan>
                                </text>
                            );
                        }}
                        outerRadius={250}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {pieChartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default H2Dashboard;
