import React, {useEffect, useState} from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import {Tab, Tabs} from "../../../components/tab";
import Section from "../../../components/section";
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import RegionStatistics from "../components/region-statistics";
import OrganizationStatistics from "../components/organization-statistics";
import {useGetAllQuery} from "../../../hooks/api";
import {KEYS} from "../../../constants/keys";
import {URLS} from "../../../constants/urls";
import DispensaryStatistics from "../components/dispensary-statistics";
import CardMap from "./CardMap";
import InpatientStatistics from "../components/inpatient-statistics";


const DashboardContainer = () => {
    const {t} = useTranslation();
    const [tab, setTab] = useState('P1')
    const [hTab, setHTab] = useState('H1')
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const {data: regionList} = useGetAllQuery({
        key: KEYS.states, url: URLS.states,
    })

    const {search} = useLocation()
    let s = search.split('&')
    const tabId = s[0].slice(1, s[0].length)

    useEffect(() => {
        if (!tabId){
            navigate(`/?tab=info`)
        }
        const newTabId = tabId?.split('=')[1]
        // setTab(newTabId)
        if (newTabId === 'info' || newTabId === 'map' || newTabId === ''){
            setTab('P1')
        } else {
            if (newTabId?.includes('H')) {
                setHTab(tabId ? tabId?.split('=')[1] : 'H1')
            } else {
                setTab(tabId ? tabId?.split('=')[1] : 'P1')
            }
        }
    }, [tabId, navigate])


    const pageTitles = [
        {
            id: 'tab=P1',
            title: "Структура прикрепленного населения в разрезе возрастных групп и пола",
            tabTitle:  t("Прикрепленное население")
        },
        {
            id: "tab=P2",
            title: "Структура прикрепленного населения в разрезе организаций ПМСП",
            tabTitle:  t("Прикрепленное население")
        },
        {
            id: "tab=P3",
            title: "Структура прикрепленного населений в разрезе возрастных групп, %",
            tabTitle:  t("Прикрепленное население")
        },
    ]

    const pageHTitles = [
        {
            id: "tab=H1",
            title: "Структура госпитализаций в разрезе стационаров",
            tabTitle:  t("Анализ стационарных госпитализаций (Терапия)")
        },
        {
            id: "tab=H2",
            title: "Структура больничной смертности в разрезе классов МКБ10",
            tabTitle:  t("Анализ стационарных госпитализаций (Терапия)")
        }
    ]

    const renderTitle = (tab, items) => {
        const newItem = items.find(item => `${item.id}` === tabId)
        return tab ? newItem?.tabTitle : newItem?.title
    }

    const tabsTitles = [
        {
            id: 'tab=P1',
            cat: 'P1',
            body: <RegionStatistics category='P1' regionList={regionList} title={renderTitle(false, pageTitles)}/>
        },
        {
            id: "tab=P2",
            cat: 'P2',
            body: <RegionStatistics category='P2' regionList={regionList} title={renderTitle(false, pageTitles)}/>
        },
        {
            id: "tab=P3",
            cat: 'P3',
            body: <RegionStatistics category='P3' regionList={regionList} title={renderTitle(false, pageTitles)}/>
              // <DispensaryStatistics regionList={regionList}/>
        }
    ]
    const hTabsTitles = [
        {
            id: "tab=H1",
            cat: 'H1',
            body: <InpatientStatistics category="H1" title={renderTitle(false, pageHTitles)}/>
        },
        {
            id: "tab=H2",
            cat: 'H2',
            body: <InpatientStatistics category="H2" title={renderTitle(false, pageHTitles)}/>
        }
    ]

    const renderTab = (body, items) => {
        const newItem = items.find(item => `${item.id}` === tabId)
        return body ? newItem?.body : newItem?.cat
    }
    const renderHTab = (body, items) => {
        const newItem = items.find(item => `${item.id}` === tabId)
        return body ? newItem?.body : newItem?.cat
    }
    return (
        <div>
            <Tabs>
                <Tab tab={'info'} label={t("Title page")}>
                    <Section>
                        <Title className={'mb-4 !text-2xl'}>{t("Анализ информации медицинских организаций")}</Title>
                        <div>
                            <div>
                                <div
                                    className={'bg-[#E5F0F3] py-1 px-2 font-semibold text-lg'}>{t("Раздел 1. Прикрепленное население")}
                                </div>
                                <ul className={'py-5'}>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура прикрепленного населения в разрезе возрастных групп и пола')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=P1',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>P1
                                        </Link>
                                    </li>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура прикрепленного населения в разрезе организаций ПМСП')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=P2',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>P2
                                        </Link>
                                    </li>
                                    <li className={' flex justify-between'}>
                                        <span>{t('Структура прикрепленного населений в разрезе возрастных групп, %')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=P3',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>P3
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <div className={'bg-[#E5F0F3] py-1 px-2 font-semibold text-lg'}>
                                    {t('Раздел 2. Анализ стационарных госпитализаций (Терапия)')}
                                </div>
                                <ul className={'py-5'}>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура госпитализаций в разрезе стационаров')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H1',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H1
                                        </Link>
                                    </li>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура больничной смертности в разрезе классов МКБ10')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H2',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H2
                                        </Link>
                                    </li>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура госпитализаций по классам МКБ10 в разрезе стационаров')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H3',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H3
                                        </Link>
                                    </li>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Анализ среднего пребывания по выбранной клинической категории')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H4',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H4
                                        </Link>
                                    </li>
                                    <li className={' flex justify-between'}>
                                        <span>{t('Анализ спектра госпитализаций в разрезе классов МКБ10')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H5',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H5
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <div className={'bg-[#E5F0F3] py-1 px-2 font-semibold text-lg'}>
                                    {t('Раздел 3. Анализ стационарных госпитализаций (Хирургия)')}
                                </div>
                                <ul className={'py-5'}>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура хирургических операций в разрезе стационаров')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=HS1',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>HS1
                                        </Link>
                                    </li>
                                    <li className={'flex justify-between'}>
                                        <span>{t('Структура хирургических операций в разрезе типов операций по стационарам')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=HS2',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>HS2
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Section>
                </Tab>
                <Tab tab={tab} label={
                    <div>
                        {renderTitle(true, pageTitles) || t("Прикрепленное население")}
                    <div>
                        {renderTab(false, tabsTitles)}
                    </div>
                </div>}>

                    {searchParams.get('organizationId') ?
                      <DispensaryStatistics regionList={regionList} title={renderTitle(false, pageTitles)} /> : searchParams.get('regionId') ? <OrganizationStatistics regionList={regionList} title={renderTitle(false, pageTitles)} /> : renderTab(true, tabsTitles)}
                </Tab>
                <Tab tab={hTab} label={
                    <div>
                        {renderTitle(true, pageHTitles) || t("Анализ стационарных госпитализаций (Терапия)")}
                        <div>
                            {renderHTab(false, hTabsTitles)}
                        </div>
                    </div>}>

                    {renderTab(true, hTabsTitles)}
                </Tab>
                <Tab tab="map" label={<div>{t('Статистика на карте')}
                </div>}>
                    <CardMap regionList={regionList} />
                </Tab>
                {/*<Tab tab={'P2'} label={<div>{t("Прикрепленное население")}*/}
                {/*    <div>P2</div>*/}
                {/*</div>}>*/}
                {/*    <OrganizationStatistics regionList={regionList}/>*/}
                {/*</Tab>*/}
                {/*<Tab tab={'P3'} label={<div>{t("Прикрепленное население")}*/}
                {/*    <div>P3</div>*/}
                {/*</div>}>*/}
                {/*    <DispensaryStatistics regionList={regionList}/>*/}
                {/*</Tab>*/}

            </Tabs>
        </div>
    );
};

export default DashboardContainer;
