export const KEYS = {
    oauth: 'oauth',
    token: 'token',
    getMe: 'getMe',
    login: 'login',
    outpatientReports: 'outpatientReports',
    inpatientReports: 'inpatientReports',
    baseRates: 'baseRates',
    patientGroups: 'patientGroups',
    drgs: 'drgs',
    organizationLevel: 'organizationLevel',
    organizationType: 'organizationType',
    inpatientBaseRates: 'inpatientBaseRates',
    tbBaseRates: 'tbBaseRates',
    locales: 'locales',
    translations: 'translations',
    reports: 'reports',
    drg_report: 'drg_report',
    states: 'states',
    dashboardReports: 'dashboardReports',
    cities: 'cities',
    months: 'months',
    practitioner: 'practitioner',
    allInpatientReports: 'allInpatientReports',
    icd10: 'icd10',
    organizations: 'organizations',
    notifications:'notifications'
}
