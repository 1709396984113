import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetAllQuery } from "../../../../hooks/api";
import { KEYS } from "../../../../constants/keys";
import { URLS } from "../../../../constants/urls";
import { useNavigate, useSearchParams } from "react-router-dom";
import Section from "../../../../components/section";
import Title from "../../../../components/title";
import dayjs from "dayjs";
import ChartDatepicker from "../chart-datepicker";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { OverlayLoader } from "../../../../components/loader";
import H2Dashboard from "./h2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, ChartDataLabels);

const InpatientStatistics = ({ title = '', category = 'H1' }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [month, setMonth] = useState(searchParams.get('month') ?? dayjs().subtract(1, 'month').format('YYYYMM'));
  const [viewMode, setViewMode] = useState('percentage');

  const { data: reports, isLoading: isLoadingReports } = useGetAllQuery({
    key: [KEYS.dashboardReports, month],
    url: `${URLS.dashboardReportsInpatient}/${month}/states`,
    enabled: !!month
  });

  useEffect(() => {
    if (month) {
      navigate(`/?tab=${searchParams.get('tab')}&month=${month}`);
    }
  }, [month, navigate, searchParams]);

  let rawData = reports?.data?.data || [];

  const processGroupedData = (data) => {
    const grouped = {};

    data?.forEach(region => {
      region?.drg_groups?.forEach(group => {
        if (!group?.code) return;

        const firstLetter = group.code.charAt(0);

        if (!grouped[firstLetter]) {
          grouped[firstLetter] = {
            codeName: firstLetter,
            value: 0,
            details: []
          };
        }

        grouped[firstLetter].value += group.report_forms_count || 0;

        grouped[firstLetter].details.push({
          code: group.code,
          name: group.name,
          count: group.report_forms_count || 0
        });
      });
    });

    return Object.values(grouped);
  };

  const groupedData = processGroupedData(rawData);

  const labels = rawData.map(region => region.name);
  const totalFormsByRegion = rawData.map(region =>
      region.drg_groups.reduce((sum, group) => sum + (group.report_forms_count || 0), 0)
  );

  const datasets = groupedData.map(group => {
    return {
      label: `Группа ${group.codeName}`,
      data: labels.map((region, index) => {
        const regionData = rawData.find(r => r.name === region);
        const groupData = regionData?.drg_groups.filter(g => g.code.startsWith(group.codeName)) || [];
        const count = groupData.reduce((sum, g) => sum + (g.report_forms_count || 0), 0);

        if (viewMode === "percentage") {
          const total = totalFormsByRegion[index] || 1; // Prevent division by 0
          return ((count / total) * 100).toFixed(2);
        }

        return count;
      }),
      backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
          Math.random() * 254
      )}, 0.7)`,
    };
  });

  const chartData = {
    labels,
    datasets,
  };

  const chartOptions = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false, // Disable default tooltip
        external: function (context) {
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create tooltip container if it doesn't exist
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.backgroundColor = 'white';
            tooltipEl.style.border = '1px solid #ccc';
            tooltipEl.style.padding = '10px';
            tooltipEl.style.boxShadow = '0 4px 8px rgba(0,0,0,0.2)';
            tooltipEl.style.borderRadius = '5px';
            tooltipEl.style.zIndex = 1000;
            tooltipEl.style.fontSize = '14px';
            tooltipEl.style.maxWidth = '300px';
            tooltipEl.style.overflowY = 'auto';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.opacity = '0';
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context.tooltip;
          if (!tooltipModel || !tooltipModel.dataPoints) {
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.opacity = '0';
            return;
          }

          const dataPoint = tooltipModel.dataPoints[0];
          const region = rawData[dataPoint.dataIndex];
          const groupCode = dataPoint.dataset.label.split(" ")[1];

          const groupData = region.drg_groups.filter(g => g.code.startsWith(groupCode)) || [];
          const groupTotal = groupData.reduce((sum, g) => sum + (g.report_forms_count || 0), 0);
          const total = totalFormsByRegion[dataPoint.dataIndex] || 1;
          const groupPercentage = ((groupTotal / total) * 100).toFixed(2);

          let tooltipHTML = `
          <div><strong>Мед. организация:</strong> ${region?.name ?? 'Неизвестно'}</div>
          <div><strong>Группа ${groupCode}:</strong> ${groupTotal} 
          ${viewMode === "percentage" ? `(<span>${groupPercentage}%</span>)` : ''}</div>
          <hr style="margin: 5px 0; border: 0.5px solid #ddd;">
        `;

          groupData.forEach(g => {
            const itemPercentage = ((g.report_forms_count / groupTotal) * 100).toFixed(2);
            tooltipHTML += `
            <div style="margin-left: 10px;">
              <span><strong>Код:</strong> ${g.code} | </span>
              <span>${g.name} | </span>
              <span>${viewMode === "percentage" ? `<span>${itemPercentage}%</span>` : g.report_forms_count}</span>
            </div>
          `;
          });


          tooltipEl.innerHTML = tooltipHTML;

          const position = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.left = `${position.left + window.pageXOffset + dataPoint.element.x + 15}px`;
          tooltipEl.style.top = `${position.top + window.pageYOffset + dataPoint.element.y}px`;
          tooltipEl.style.opacity = '1'; // Show tooltip when hovering

          document.addEventListener("mousemove", (event) => {
            const mouseX = event.clientX;
            const mouseY = event.clientY;

            if (
                mouseX < position.left ||
                mouseX > position.right ||
                mouseY < position.top ||
                mouseY > position.bottom
            ) {
              tooltipEl.style.opacity = '0';
              tooltipEl.style.pointerEvents = 'none';
            }
          });
        }
      },
      legend: {
        display: false,
        position: 'bottom',
      },
    },
    scales: {
      y: {
        stacked: true,
        title: {
          display: false,
          text: '',
        },
      },
      x: {
        stacked: true,
        title: {
          display: false,
          text: viewMode === "percentage" ? "Percentage (%)" : "Report Forms Count",
        },
        ticks: {
          callback: function (value) {
            return viewMode === "percentage" ? `${value}%` : value;
          },
        },
      },
    },
  };

  return (
      <>
        <Section>
          <Title className={'mb-4 !text-2xl'}>
            {t(title)}
          </Title>
          <div className="w-full flex justify-end gap-2">
            {
                category === 'H1' && (
                    <div className="w-full flex justify-end items-center">
                      <select
                          value={viewMode}
                          onChange={(e) => setViewMode(e.target.value)}
                          className="border px-4 py-2 rounded"
                      >
                        <option value="quantity">{t('Количество')}</option>
                        <option value="percentage">{t('Проценты')}</option>
                      </select>
                    </div>
                )
            }
            <ChartDatepicker month={month} setMonth={setMonth} keys={KEYS.inpatientReports} url={URLS.inpatientReports} />
          </div>
          {isLoadingReports ? <OverlayLoader /> : category === 'H2' ? <H2Dashboard rawData={rawData} /> : <Bar data={chartData} options={chartOptions} />}
        </Section>
      </>
  );
};

export default InpatientStatistics;
