
import {get} from "lodash";
import {useSettingsStore, useStore} from "../../store";

const useAuth = () => {
    const isAuthenticated = useStore(state => get(state, 'isAuthenticated', false))
    const user = useStore(state => get(state, 'user', null))
    const permissions = useStore(state => get(state, 'user.permissions', []))
    const roles = useStore(state => get(state, 'user.roles', []))
    const token = useSettingsStore(state => get(state, 'token', null))
    return {
        user,
        isAuthenticated,
        token,
        permissions,
        roles
    }
};

export default useAuth;